.workspaceSelectorGroupHeading {
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background-color: #b6c5cf;
  color: #022738;
  display: block;
  width: 100%;
  padding: 6px;
  margin: 0;
  font-size: 10px;
}

.workspaceSelectorOuter {
  border-bottom: 1px solid #b6c5cf;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workspaceSelectorOption {
  margin: 3px;
  padding: 3px;
  font-size: 14px;
}

.workspaceSelectorOptionSelectable {
  cursor: pointer;
}

.workspaceSelectorOptionSelectable:hover {
  background-color: #4ab1ec;
  color: #f2f7fa;
}

.searchInput {
  background-color: #263449;
  color: #f2f7fa !important;
  padding: 8px;
  cursor: pointer;
}

.autoComplete {
  width: 350px;
  cursor: pointer;
}

.autocomplete::selected {
  border: 3px solid orange;
}
