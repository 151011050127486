.button {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-family: Inter;
  cursor: pointer;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  overflow: visible;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 0;
  border: none;

  &--primary {
    background: #ec3b26;
    color: white;

    &:hover,
    &:active {
      background: $color-ui-red-600;
      color: white;
    }

    &:focus {
      outline: none;
    }
  }

  &--secondary {
    background: white;
    border: 1px solid #dbe3eb;
    color: $color-link;

    &:hover,
    &:active {
      color: $color-link-600;
    }

    &:focus {
      outline: none;
    }
  }

  &--secondary-alt {
    background: white;
    border-bottom: 1px solid #dbe3eb;
    color: $color-link;

    &:hover,
    &:active {
      color: $color-link-600;
    }

    &:focus {
      outline: none;
    }
  }

  &--small {
    padding-left: 12px;
    padding-right: 12px;
    min-height: 32px;
  }

  &--medium {
    padding-left: 18px;
    padding-right: 18px;
    min-height: 40px;
  }

  &--large {
    padding-left: 25px;
    padding-right: 25px;
    min-height: 50px;
  }
}
