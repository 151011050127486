.mw-100 {
  max-width: 100%;
}

.mw1 {
  max-width: $space-3;
}

.mw2 {
  max-width: $space-4;
}

.mw3 {
  max-width: $space-5;
}

.mw4 {
  max-width: $space-6;
}

.mw5 {
  max-width: $space-7;
}

.mw6 {
  max-width: $space-8;
}

.mw7 {
  max-width: $space-9;
}

.mw8 {
  max-width: $space-10;
}
