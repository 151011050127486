.grid {
  margin-left: -$space-3;
  display: flex;
  flex-wrap: wrap;
}

.grid > * {
  padding-left: $space-3;
  width: 100%;
}

.grid-wide {
  margin-left: -$space-5;
}

.grid-wide > * {
  padding-left: $space-5;
}

.grid-flush {
  margin-left: 0;
}

.grid-flush > * {
  padding-left: 0;
}

.col-1 {
  width: 8.333333%;
}

.col-2 {
  width: 16.666666%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.333333%;
}

.col-5 {
  width: 41.666666%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.333333%;
}

.col-8 {
  width: 66.666666%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.333333%;
}

.col-11 {
  width: 91.666666%;
}

.col-12 {
  width: 100%;
}

.col-13 {
  width: 20%;
}
