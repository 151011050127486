.v-base {
  vertical-align: baseline;
}

.v-mid {
  vertical-align: middle;
}

.v-top {
  vertical-align: top;
}

.v-btm {
  vertical-align: bottom;
}
