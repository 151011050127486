.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  .tab {
    font-size: $f5;
    margin-right: $space-4;
    padding-bottom: $space-3;
    border-bottom: 2px solid transparent;
    font-weight: 500;
    color: $color-text-600;
    cursor: pointer;

    &.is-active {
      border-color: $color-ui-red;
      color: $color-text-900;
    }
  }
}
