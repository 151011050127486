@import '../variables';
@import 'reset';

$topbar-height: 84px;

html {
  // Linear gradient, lighter in top/left corner.
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $sans;
  font-weight: 400;
  line-height: 1.6;
  font-size: $f5;
  color: $color-text-900;
}

// Side navigation
// ---------------------------------------------------

#sidebar {
  width: 230px;
  position: relative;
  display: flex;

  #sidebar-logo {
    flex-grow: 0;
  }

  #sidebar-main {
    flex-grow: 1;
    overflow-y: auto;

    section {
      margin: 0 0 24px;
    }

    section:last-child {
      padding-bottom: 3em;
    }
  }

  #sidebar-footer {
    flex-grow: 0;
  }

  .logo-wrap {
    position: relative;
    height: $topbar-height;
    display: flex;
    align-items: center;
  }

  .logo-wrap {
    height: $topbar-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-white {
    display: block;
  }

  .logo-white-small {
    display: none;
  }
}

.nav {
  &-title {
    color: $color-text-500;
    font-size: $f8;
    margin-bottom: $space-1;
    padding-top: $space-1;
    padding-left: 12px; // Required to line up with icons
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
  }

  &__item {
    display: flex;
    font-size: $f5;
    color: $color-text-500;
    align-items: center;

    &__icon {
      width: 38px;
      height: 38px;
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4px;
    }

    // Icon in sidebar nav
    img {
      opacity: 0.7;
    }

    &.is-active,
    &:hover {
      color: white;
      background: $color-ui-navy-400;

      img {
        opacity: 1;
      }
    }
  }

  &-divider {
    height: 1px;
    background: #2b3746;
    margin: 0 $space-3;
    position: relative;
    top: -16px;
    display: none;
  }
}

.side-nav-toggle {
  font-size: $f7;
  opacity: 0.3;
  cursor: pointer;
  color: $color-ui-blue-200;
  padding: 8px 10px 10px;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 10px;
    height: 10px;
    margin: 2px 4px 0 0;
  }
}

.tray {
  background-color: $color-ui-navy-400;
  width: 100%;
  padding-top: 10px;

  .tray-item {
    display: flex;
    gap: 12px;
    padding: 4px 12px;
    color: white;

    img {
      opacity: 0.5;
    }

    &:hover img {
      opacity: 1;
    }
  }
}

#sidebar-control {
  cursor: pointer;
  width: 50px;
}

// Sidebar open and close states
// ---------------------------------------------------

// Styles when sidebar is collapsed. This class is on the body.
.sidebar-is-collapsed {
  #sidebar {
    width: 48px;

    .logo-white {
      display: none;
    }

    .logo-white-small {
      display: block;
    }

    .nav-divider {
      display: block;
    }

    .nav-title,
    .nav__item__label {
      display: none;
    }

    .tray {
      .tray-item > span {
        display: none;
      }
    }

    .side-nav-toggle {
      svg {
        width: 24px;
        height: 24px;
        transform: rotate(180deg) translate(2px, 0);
      }

      span {
        display: none;
      }
    }
  }
}

// Main section
// ---------------------------------------------------

#main {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  min-width: 900px;

  // Top bar above content
  // ---------------------------------------------------

  #topbar {
    height: $topbar-height;
    flex: none;
    display: flex;
    align-items: center;
  }

  .top-drop {
    width: 325px;
    height: 50px;
    flex: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: $color-ui-navy-400;
    margin-right: $space-3;
    color: $color-text-300;
    padding-left: $space-3;
    padding-right: $space-3;
  }

  .top-drop-grid-active,
  .top-drop-arrow-active {
    display: none;
  }

  .top-drop.is-active {
    background: white;
    color: $color-text-700;

    .top-drop-grid,
    .top-drop-arrow {
      display: none;
    }

    .top-drop-grid-active,
    .top-drop-arrow-active {
      display: block;
    }
  }

  // Content in light blue background area
  // ---------------------------------------------------

  #content {
    background: $color-ui-blue-200;
    position: relative;
  }

  .content-head {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-bottom: 2px solid #e4ebf0;
    padding: $space-4 $space-5 0 $space-5;

    .basket-icon {
      width: 38px;
      height: 46px;
      background: url("data:image/svg+xml,%3Csvg width='38' height='46' viewBox='0 0 38 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.53033 7.38953L0.530482 7.38951L0.528682 7.37727C0.510254 7.25194 0.50067 7.12547 0.5 6.99879C0.500429 6.06317 0.998957 5.1866 1.99024 4.37482C2.98535 3.55992 4.43242 2.85093 6.20186 2.26992C9.73621 1.1094 14.4305 0.5 19 0.5C23.5695 0.5 28.2638 1.1094 31.7981 2.26992C33.5676 2.85093 35.0147 3.55992 36.0098 4.37482C37.0011 5.18666 37.4997 6.06331 37.5 6.99902C37.4995 7.12462 37.4902 7.25004 37.4722 7.37435L37.472 7.37433L37.4707 7.38557L33.4707 40.2396L33.4706 40.2396L33.4695 40.2497C33.3924 41.012 32.9535 41.725 32.1567 42.3806C31.3573 43.0384 30.2295 43.6099 28.8612 44.0779C26.1267 45.0133 22.5372 45.5 19 45.5C15.4628 45.5 11.8735 45.0132 9.13927 44.0759C7.77116 43.6069 6.64354 43.0339 5.84437 42.374C5.04792 41.7164 4.60856 41.0002 4.5315 40.233L4.53161 40.233L4.53033 40.2225L0.53033 7.38953Z' fill='white' stroke='%23E4EBF0'/%3E%3Cpath d='M19 3C28.9125 3 35 5.6208 35 7.5C35 9.3792 28.9125 12 19 12C9.08753 12 3 9.3792 3 7.5C3 5.6208 9.08753 3 19 3Z' fill='%23E4EBF0'/%3E%3C/svg%3E%0A")
        no-repeat;
      margin-right: $space-3;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: $space-2;
      color: $color-link;
      font-weight: 600;
    }
  }

  #content-scroll {
    overflow-y: scroll;
  }
}

// Feature Detail page when breadcrumbs are showing
// ---------------------------------------------------

.content-shadow {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  flex: 1;
}

// cytoscape node tooltip from data flow diagram
.popper-div {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  padding: 5px 9px;
}

// Hides React Flow bottom ad in the Dataflow diagram
.react-flow__attribution {
  display: none;
}
