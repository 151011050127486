.color-ui-navy {
  color: $color-ui-navy;
}

.color-ui-red {
  color: $color-ui-red;
}

.color-ui-brown {
  color: $color-ui-brown;
}

.color-ui-blue {
  color: $color-ui-blue;
}

.color-ui-blue-200 {
  color: $color-ui-blue-200;
}

.color-ui-green {
  color: $color-ui-green;
}

.color-ui-yellow {
  color: $color-ui-yellow;
}

.color-ui-white {
  color: white;
}

// Text colors

.color-text-900 {
  color: $color-text-900;
}

.color-text-700 {
  color: $color-text-700;
}

.color-text-600 {
  color: $color-text-600;
}

.color-text-500 {
  color: $color-text-500;
}

.color-text-300 {
  color: $color-text-300;
}

.color-text-red {
  color: #d83327;
}

.color-text-green {
  color: #098051;
}

// Background colors

.bg-color-ui-navy {
  background-color: $color-ui-navy;
}

.bg-color-ui-red {
  background-color: $color-ui-red;
}

.bg-color-ui-blue {
  background-color: $color-ui-blue;
}

.bg-color-ui-blue-200 {
  background-color: $color-ui-blue-200;
}

.bg-color-ui-green {
  background-color: $color-ui-green;
}

.bg-color-ui-white {
  background-color: white;
}

// Hover background colors

.hover-bg-color-ui-navy {
  background-color: $color-ui-navy;
}

.hover-bg-color-ui-red {
  background-color: $color-ui-red;
}

.hover-bg-color-ui-blue {
  background-color: $color-ui-blue;
}

.hover-bg-color-ui-blue-200 {
  background-color: $color-ui-blue-200;
}

.hover-bg-color-ui-green {
  background-color: $color-ui-green;
}

.hover-bg-color-ui-white {
  background-color: white;
}
