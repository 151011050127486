.ma0 {
  margin: 0;
}

.ma1 {
  margin: $space-1;
}

.ma2 {
  margin: $space-2;
}

.ma3 {
  margin: $space-3;
}

.ma4 {
  margin: $space-4;
}

.ma5 {
  margin: $space-5;
}

.ma6 {
  margin: $space-6;
}

.ma7 {
  margin: $space-7;
}

.ma8 {
  margin: $space-8;
}

.ml0 {
  margin-left: 0;
}

.ml1 {
  margin-left: $space-1;
}

.ml2 {
  margin-left: $space-2;
}

.ml3 {
  margin-left: $space-3;
}

.ml4 {
  margin-left: $space-4;
}

.ml5 {
  margin-left: $space-5;
}

.ml6 {
  margin-left: $space-6;
}

.ml7 {
  margin-left: $space-7;
}

.ml8 {
  margin-left: $space-8;
}

.mr0 {
  margin-right: 0;
}

.mr1 {
  margin-right: $space-1;
}

.mr2 {
  margin-right: $space-2;
}

.mr3 {
  margin-right: $space-3;
}

.mr4 {
  margin-right: $space-4;
}

.mr5 {
  margin-right: $space-5;
}

.mr6 {
  margin-right: $space-6;
}

.mr7 {
  margin-right: $space-7;
}

.mr8 {
  margin-right: $space-8;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: $space-1;
}

.mb2 {
  margin-bottom: $space-2;
}

.mb3 {
  margin-bottom: $space-3;
}

.mb4 {
  margin-bottom: $space-4;
}

.mb5 {
  margin-bottom: $space-5;
}

.mb6 {
  margin-bottom: $space-6;
}

.mb7 {
  margin-bottom: $space-7;
}

.mb8 {
  margin-bottom: $space-8;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: $space-1;
}

.mt2 {
  margin-top: $space-2;
}

.mt3 {
  margin-top: $space-3;
}

.mt4 {
  margin-top: $space-4;
}

.mt5 {
  margin-top: $space-5;
}

.mt6 {
  margin-top: $space-6;
}

.mt7 {
  margin-top: $space-7;
}

.mt8 {
  margin-top: $space-8;
}

.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mv1 {
  margin-top: $space-1;
  margin-bottom: $space-1;
}

.mv2 {
  margin-top: $space-2;
  margin-bottom: $space-2;
}

.mv3 {
  margin-top: $space-3;
  margin-bottom: $space-3;
}

.mv4 {
  margin-top: $space-4;
  margin-bottom: $space-4;
}

.mv5 {
  margin-top: $space-5;
  margin-bottom: $space-5;
}

.mv6 {
  margin-top: $space-6;
  margin-bottom: $space-6;
}

.mv7 {
  margin-top: $space-7;
  margin-bottom: $space-7;
}

.mv8 {
  margin-top: $space-8;
  margin-bottom: $space-8;
}

.mh0 {
  margin-left: 0;
  margin-right: 0;
}

.mh1 {
  margin-left: $space-1;
  margin-right: $space-1;
}

.mh2 {
  margin-left: $space-2;
  margin-right: $space-2;
}

.mh3 {
  margin-left: $space-3;
  margin-right: $space-3;
}

.mh4 {
  margin-left: $space-4;
  margin-right: $space-4;
}

.mh5 {
  margin-left: $space-5;
  margin-right: $space-5;
}

.mh6 {
  margin-left: $space-6;
  margin-right: $space-6;
}

.mh7 {
  margin-left: $space-7;
  margin-right: $space-7;
}

.mh8 {
  margin-left: $space-8;
  margin-right: $space-8;
}

.-ma1 {
  margin: -4px;
}

.-ma2 {
  margin: -$space-2;
}

.-ma3 {
  margin: -$space-3;
}

.-ma4 {
  margin: -$space-4;
}

.-ma5 {
  margin: -$space-5;
}

.-ma6 {
  margin: -$space-6;
}

.-ma7 {
  margin: -$space-7;
}

.-ma8 {
  margin: -$space-8;
}

.-ml1 {
  margin-left: -4px;
}

.-ml2 {
  margin-left: -$space-2;
}

.-ml3 {
  margin-left: -$space-3;
}

.-ml4 {
  margin-left: -$space-4;
}

.-ml5 {
  margin-left: -$space-5;
}

.-ml6 {
  margin-left: -$space-6;
}

.-ml7 {
  margin-left: -$space-7;
}

.-ml8 {
  margin-left: -$space-8;
}

.-mr1 {
  margin-right: -4px;
}

.-mr2 {
  margin-right: -$space-2;
}

.-mr3 {
  margin-right: -$space-3;
}

.-mr4 {
  margin-right: -$space-4;
}

.-mr5 {
  margin-right: -$space-5;
}

.-mr6 {
  margin-right: -$space-6;
}

.-mr7 {
  margin-right: -$space-7;
}

.-mr8 {
  margin-right: -$space-8;
}

.-mb1 {
  margin-bottom: -4px;
}

.-mb2 {
  margin-bottom: -$space-2;
}

.-mb3 {
  margin-bottom: -$space-3;
}

.-mb4 {
  margin-bottom: -$space-4;
}

.-mb5 {
  margin-bottom: -$space-5;
}

.-mb6 {
  margin-bottom: -$space-6;
}

.-mb7 {
  margin-bottom: -$space-7;
}

.-mb8 {
  margin-bottom: -$space-8;
}

.-mt1 {
  margin-top: -4px;
}

.-mt2 {
  margin-top: -$space-2;
}

.-mt3 {
  margin-top: -$space-3;
}

.-mt4 {
  margin-top: -$space-4;
}

.-mt5 {
  margin-top: -$space-5;
}

.-mt6 {
  margin-top: -$space-6;
}

.-mt7 {
  margin-top: -$space-7;
}

.-mt8 {
  margin-top: -$space-8;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.ma-auto {
  margin: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}
