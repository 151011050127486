.param-wrap {
  height: 34px;
  padding: 3px 5px;
  background: white;
  border: 1px solid #ddd;
}

.param {
  height: 22px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  display: inline-block;
  background: white;
  padding-left: 8px;
  padding-right: 8px;

  b {
    font-weight: 600;
  }

  &--1 {
    border-color: #662d00;
    color: #662d00;
    background: #fdf4ed;
  }

  &--2 {
    border-color: #00636e;
    color: #00636e;
    background: #eefcfe;
  }

  &--3 {
    border-color: #005943;
    color: #005943;
    background: #effcf9;
  }

  &--4 {
    border-color: #6e002c;
    color: #6e002c;
    background: #feeef4;
  }

  &--5 {
    border-color: #220080;
    color: #220080;
    background: #f5f1ff;
  }
}
