.radio:checked,
.radio:not(:checked) {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
}

.radio:checked + label,
.radio:not(:checked) + label {
  position: relative;
  padding-left: $space-4;
  cursor: pointer;
  display: inline-block;
  color: $color-text-900;
}

.radio:checked + label:before,
.radio:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #b6c5cf;
  border-radius: 100%;
  background: #fff;
}

.radio:checked + label:before {
  background: $color-ui-blue;
}

.radio:checked + label:after,
.radio:not(:checked) + label:after {
  content: '';
  width: 6px;
  height: 6px;
  background: white;
  position: absolute;
  top: 7px;
  left: 5px;
  border-radius: 100%;
}

// Disabled states

.radio:disabled:checked + label,
.radio:disabled:not(:checked) + label {
  color: $color-text-500;
  cursor: default;
}

.radio:disabled:checked + label:before {
  background: #e9ecee;
}

.radio:disabled:checked + label:after {
  background: #b6c5cf;
}
