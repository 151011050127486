.pa0 {
  padding: 0;
}

.pa1 {
  padding: $space-1;
}

.pa2 {
  padding: $space-2;
}

.pa3 {
  padding: $space-3;
}

.pa4 {
  padding: $space-4;
}

.pa5 {
  padding: $space-5;
}

.pa6 {
  padding: $space-6;
}

.pa7 {
  padding: $space-7;
}

.pa8 {
  padding: $space-8;
}

.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: $space-1;
}

.pl2 {
  padding-left: $space-2;
}

.pl3 {
  padding-left: $space-3;
}

.pl4 {
  padding-left: $space-4;
}

.pl5 {
  padding-left: $space-5;
}

.pl6 {
  padding-left: $space-6;
}

.pl7 {
  padding-left: $space-7;
}

.pl8 {
  padding-left: $space-8;
}

.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: $space-1;
}

.pr2 {
  padding-right: $space-2;
}

.pr3 {
  padding-right: $space-3;
}

.pr4 {
  padding-right: $space-4;
}

.pr5 {
  padding-right: $space-5;
}

.pr6 {
  padding-right: $space-6;
}

.pr7 {
  padding-right: $space-7;
}

.pr8 {
  padding-right: $space-8;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: $space-1;
}

.pb2 {
  padding-bottom: $space-2;
}

.pb3 {
  padding-bottom: $space-3;
}

.pb4 {
  padding-bottom: $space-4;
}

.pb5 {
  padding-bottom: $space-5;
}

.pb6 {
  padding-bottom: $space-6;
}

.pb7 {
  padding-bottom: $space-7;
}

.pb8 {
  padding-bottom: $space-8;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: $space-1;
}

.pt2 {
  padding-top: $space-2;
}

.pt3 {
  padding-top: $space-3;
}

.pt4 {
  padding-top: $space-4;
}

.pt5 {
  padding-top: $space-5;
}

.pt6 {
  padding-top: $space-6;
}

.pt7 {
  padding-top: $space-7;
}

.pt8 {
  padding-top: $space-8;
}

.pv0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pv1 {
  padding-top: $space-1;
  padding-bottom: $space-1;
}

.pv2 {
  padding-top: $space-2;
  padding-bottom: $space-2;
}

.pv3 {
  padding-top: $space-3;
  padding-bottom: $space-3;
}

.pv4 {
  padding-top: $space-4;
  padding-bottom: $space-4;
}

.pv5 {
  padding-top: $space-5;
  padding-bottom: $space-5;
}

.pv6 {
  padding-top: $space-6;
  padding-bottom: $space-6;
}

.pv7 {
  padding-top: $space-7;
  padding-bottom: $space-7;
}

.pv8 {
  padding-top: $space-8;
  padding-bottom: $space-8;
}

.ph0 {
  padding-left: 0;
  padding-right: 0;
}

.ph1 {
  padding-left: $space-1;
  padding-right: $space-1;
}

.ph2 {
  padding-left: $space-2;
  padding-right: $space-2;
}

.ph3 {
  padding-left: $space-3;
  padding-right: $space-3;
}

.ph4 {
  padding-left: $space-4;
  padding-right: $space-4;
}

.ph5 {
  padding-left: $space-5;
  padding-right: $space-5;
}

.ph6 {
  padding-left: $space-6;
  padding-right: $space-6;
}

.ph7 {
  padding-left: $space-7;
  padding-right: $space-7;
}

.ph8 {
  padding-left: $space-8;
  padding-right: $space-8;
}
