.text-input {
  width: 100%;
  background-color: white;
  border: 2px solid #dbe3eb;
  padding: 8px 15px;
  height: 38px;
  font-size: $f6;
  vertical-align: middle;

  &::-webkit-input-placeholder {
    color: $color-text-500;
  }

  &::-moz-placeholder {
    color: $color-text-500;
  }

  &:-ms-input-placeholder {
    color: $color-text-500;
  }

  &:focus {
    outline: none;
    color: $color-text-900;
    border-color: $color-ui-blue;
  }

  &--search {
    padding-left: 44px;
    background-repeat: no-repeat;
    background-position: left 16px center;
  }

  &--error {
    border-color: $color-ui-red;

    &:focus {
      border-color: $color-ui-red;
    }
  }

  &--large {
    height: 50px;
    background-color: $color-ui-navy-400;
    border-color: $color-ui-navy;

    &:focus {
      background-color: white;
    }
  }
}
