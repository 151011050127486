.search-dropdown {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &__title {
    padding: $space-2;
    background: $color-ui-blue-200;
    font-size: $f6;
    font-weight: 500;
    color: $color-text-500;
  }
}
