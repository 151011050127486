.poptip {
  background: $color-ui-navy;
  padding: $space-2 $space-3;
  color: white;
  max-width: 180px;
  font-size: $f7;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  position: relative;

  &:after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $color-ui-navy;
  }
}
.__react_component_tooltip {
  border-radius: 0px !important;
  font-size: 12px !important;
  background-color: #022738 !important;
  padding-bottom: 8px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 8px !important;
  text-align: center !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}
