.with-docs-column-wrapper {
  display: grid;
  grid-template-columns: 1fr 530px;
  grid-gap: 4px;
  align-items: start;
  height: 100%;
  overflow: hidden;
}

.main-column {
  height: 100%;
  background-color: white;
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.docs-column {
  height: 100%;
  overflow-y: auto;
  padding-right: 24px;
}

.main-column,
.docs-column {
  padding-top: 24px;
  padding-bottom: 24px;
}
