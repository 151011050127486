.w1 {
  width: $space-3;
}

.w2 {
  width: $space-4;
}

.w3 {
  width: $space-5;
}

.w4 {
  width: $space-6;
}

.w5 {
  width: $space-7;
}

.w6 {
  width: $space-8;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33.333333%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-66 {
  width: 66.666666%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}
