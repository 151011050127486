.top1 {
  top: $space-2;
}

.right1 {
  right: $space-2;
}

.bottom1 {
  bottom: $space-2;
}

.left1 {
  left: $space-2;
}

.top2 {
  top: $space-3;
}

.right2 {
  right: $space-3;
}

.bottom2 {
  bottom: $space-3;
}

.left2 {
  left: $space-3;
}

.-top1 {
  top: -$space-2;
}

.-right1 {
  right: -$space-2;
}

.-bottom1 {
  bottom: -$space-2;
}

.-left1 {
  left: -$space-2;
}

.-top2 {
  top: -$space-3;
}

.-right2 {
  right: -$space-3;
}

.-bottom2 {
  bottom: -$space-3;
}

.-left2 {
  left: -$space-3;
}

.absolute-fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: 1px;
}

.top-2 {
  top: 2px;
}

.top-3 {
  top: 3px;
}

.top-4 {
  top: 4px;
}

.top-5 {
  top: 5px;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 1px;
}

.right-2 {
  right: 2px;
}

.right-3 {
  right: 3px;
}

.right-4 {
  right: 4px;
}

.right-5 {
  right: 5px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: 1px;
}

.bottom-2 {
  bottom: 2px;
}

.bottom-3 {
  bottom: 3px;
}

.bottom-4 {
  bottom: 4px;
}

.bottom-5 {
  bottom: 5px;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: 1px;
}

.left-2 {
  left: 2px;
}

.left-3 {
  left: 3px;
}

.left-4 {
  left: 4px;
}

.left-5 {
  left: 5px;
}

.-top-1 {
  top: -1px;
}

.-top-2 {
  top: -2px;
}

.-top-3 {
  top: -3px;
}

.-top-4 {
  top: -4px;
}

.-top-5 {
  top: -5px;
}

.-right-1 {
  right: -1px;
}

.-right-2 {
  right: -2px;
}

.-right-3 {
  right: -3px;
}

.-right-4 {
  right: -4px;
}

.-right-5 {
  right: -5px;
}

.-bottom-1 {
  bottom: -1px;
}

.-bottom-2 {
  bottom: -2px;
}

.-bottom-3 {
  bottom: -3px;
}

.-bottom-4 {
  bottom: -4px;
}

.-bottom-5 {
  bottom: -5px;
}

.-left-1 {
  left: -1px;
}

.-left-2 {
  left: -2px;
}

.-left-3 {
  left: -3px;
}

.-left-4 {
  left: -4px;
}

.-left-5 {
  left: -5px;
}

.fl {
  float: left;
}

.fr {
  float: right;
}
