.data-control {
  display: inline-block;
  position: relative;
  top: 0;
  right: $space-5;
  cursor: pointer;

  &__item {
    display: inline-block;
    height: $space-5;
    vertical-align: middle;
    background: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  }

  &__move {
    margin-right: $space-3;
  }

  &__move,
  &__zoom-in,
  &__zoom-out {
    cursor: pointer;
    width: $space-5;
  }

  &__percentage {
    border-left: 1px solid #e8eef3;
    border-right: 1px solid #e8eef3;
    width: $space-6;
    text-align: center;
    font-weight: 700;
    font-size: $f7;
    padding-top: 4px;
  }
}
