.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-inherit {
  z-index: inherit;
}

.z-initial {
  z-index: initial;
}

.z-unset {
  z-index: unset;
}
