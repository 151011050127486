.ttc {
  text-transform: capitalize;
}

.ttl {
  text-transform: lowercase;
}

.ttu {
  text-transform: uppercase;
}

.ttn {
  text-transform: none;
}
