.positive-green {
  color: #0fc47e;
}

.negative-red {
  color: #d42915;
}

.editAccessLink {
  text-decoration: none !important;
  cursor: pointer !important;
  color: #0179d0;
}

.editAccessLink:hover {
  color: #0268b3;
  text-decoration: underline !important;
}

.revokeAccessLink {
  color: #a52a2a !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.revokeAccessLink:hover {
  color: #d42915;
  text-decoration: underline !important;
}

.workspace-name {
  display: inline-block;
  line-height: inherit;
}

.info-card-title {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #455569;
  font-size: 18px;
}

.admin-properties {
  max-height: 200px;
  overflow: scroll;
  overflow-wrap: anywhere;
}

.show-scrollbar {
}

.show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.show-scrollbar::-webkit-scrollbar-track {
  background-color: #aaa;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.access-control-loading-icon {
  animation: spin-in-progress linear 2s infinite;
  color: '#0f8aff';
}

@keyframes spin-in-progress {
  0% {
    transform: rotate(0deg) scaleX(-1);
  }
  100% {
    transform: rotate(360deg) scaleX(-1);
  }
}

.dynamic-table-outer-container {
  height: calc(100vh - 84px - 91px - 24px);
}

.dynamic-table-inner-container {
  height: calc(100% - 60px);
}

.tableHeadingCell {
  padding-bottom: 0 !important;
  vertical-align: bottom !important;
  cursor: default;
  // These are translated vertically two pixels as a workaround for a bug that pushed some of the scrolling table body content behind the header.
  // This is an issue specific to MUI stickHeader tables
  transform: translateY(-2px);
}

.tableHeadingDiv {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.pageSubheading {
  text-transform: uppercase;
  color: #6c7682;
  line-height: 6px;
  font-size: 14px;
}

.tableRowSelectable:hover {
  background-color: #f2f7fa;
}

.truncatedTableCell {
  display: inline-block;
  width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smallModalContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.smallModalInnerContainer {
  background-color: white;
  width: 720px;
  left: 0;
  overflow: hidden;
  margin: auto;
}

.mediumModalInnerContainer {
  width: 60%;
  background-color: white;
  left: 0;
  overflow: hidden;
  margin: auto;
}
