.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &__label {
    font-size: $f6;
    font-weight: 400;
  }

  &__image {
    width: 16px;
    height: 16px;
    border: 1px solid #b6c5cf;
    margin-right: $space-2;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-color: $color-ui-blue;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;

    &:checked + .checkbox__image {
      border-color: $color-ui-blue;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.40671 5.92666L1.33338 3.85332L0.626709 4.55999L3.40671 7.33332L9.37338 1.36666L8.66671 0.666656L3.40671 5.92666Z' fill='white'/%3E%3C/svg%3E%0A");
      background-position: center center;
      background-repeat: no-repeat;
      background-color: $color-ui-blue;
    }

    &:disabled {
      + .checkbox__image {
        background: #e9ecee;
        border-color: #b6c5cf;

        &:hover {
          border-color: #b6c5cf;
        }
      }

      &:checked + .checkbox__image {
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.40671 5.92666L1.33338 3.85332L0.626709 4.55999L3.40671 7.33332L9.37338 1.36666L8.66671 0.666656L3.40671 5.92666Z' fill='%23b6c5cf'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center center;
      }

      ~ .checkbox__label {
        color: $color-text-500;
      }
    }
  }

  // Need to change cursor on entire label
  &--disabled {
    cursor: default;
  }
}
