.mainColumn {
  min-width: 30em;
  max-width: 50em;
  margin: auto;
}

.mainColumnBlock {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.sectionSubHeading {
  font-style: italic;
  font-size: 14px;
  color: #74808e;
  font-weight: 400;
  line-height: 17px;
}

.instructionsLeadParagraph {
  margin-bottom: 24px;
  font-size: 16px;
}

.instructionsStep {
  margin-top: 24px;
  margin-bottom: 36px;
}

.instructionsStepContent {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  margin-top: 12px;
}

.instructionsList {
  margin-left: 12px;
  margin-right: 12px;
  padding: 12px;
}

.instructionsListItem {
  margin-bottom: 12px;
  line-height: 24px;
}

.terminalCommand {
  background-color: #022738;
  color: #f2f7fa;
  font-weight: 600;
  padding: 6px;
  margin: 3px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
}

.terminalCommandCopyButton {
  color: #74808e !important;
  font-size: 12px !important;
}

.instructionsHeading {
  padding-left: 6px;
}

.terminalHeading {
  color: #74808e !important;
  font-size: 10px !important;
  text-transform: uppercase;
}

.workspaceColumns {
  column-count: 4;
}

.workspaceColumnWrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 12px;
}

.workspaceColumnWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.workspaceColumnWrapper::-webkit-scrollbar-track {
  background-color: #aaa;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.workspaceColumnWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ellipsisWorkspaceName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: baseline;
}
