@import '../assets/scss/variables';

.loadingIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

:export {
  loadingIndicatorColor: $color-text-300;
}

.materializationCosts {
  margin-top: 2 * $space-2;
}

.groupItem {
  margin-left: 2 * $space-2;
}
