// Colors
// ----------------------

// For UI elements
$color-ui-navy: #022738;
$color-ui-navy-400: #263549;
$color-ui-red: #ec3b26;
$color-ui-brown: #a52a2a;
$color-ui-red-600: #d42915;
$color-ui-blue: #0f8aff;
$color-ui-blue-200: #f2f7fa;
$color-ui-green: #0fc47e;
$color-ui-yellow: #f4b63f;

// Text colors
$color-text-900: #022738;
$color-text-700: #41566b;
$color-text-600: #6c7682;
$color-text-500: #8f9cac;
$color-text-300: #b6c5cf;

// Link color
$color-link: #0179d0;
$color-link-600: #0268b3;

// Spacers
// ----------------------
$space-1: 3px;
$space-2: 6px;
$space-3: 12px;
$space-4: 18px;
$space-5: 24px;
$space-6: 48px;
$space-7: 96px;
$space-8: 192px;
$space-9: 384px;
$space-10: 552px;

// Font sizing
// ----------------------
$f3: 24px;
$f4: 16px;
$f5: 14px;
$f6: 13px;
$f7: 12px;
$f8: 10px;

// Font Families
// ----------------------
$sans: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

$mono: 'B612 Mono', monospace;

// Shadows
// ----------------------
$shadow-100: 0px 1px 3px rgba(0, 0, 0, 0.1);
$shadow-200: 0px 2px 4px rgba(0, 0, 0, 0.15);
$shadow-300: 0px 2px 7px rgba(0, 0, 0, 0.15);
$shadow-400: 0px 2px 10px rgba(0, 0, 0, 0.2);
