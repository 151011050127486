.ls1 {
  letter-spacing: 0.5px;
}

.ls2 {
  letter-spacing: 1px;
}

.ls3 {
  letter-spacing: 1.5px;
}

.-ls1 {
  letter-spacing: -0.5px;
}

.-ls2 {
  letter-spacing: -1px;
}

.-ls3 {
  letter-spacing: -1.5px;
}
