.page {
  // display: flex;
  // justify-content: space-between;
  align-items: center;

  // &__group {
  //   display: flex;

  //   .page-button {
  //     margin: 0 8px;
  //   }
  // }

  &-button {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    font-family: Inter;
    user-select: none;
    overflow: visible;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 0;
    border: none;
    color: $color-link;
    padding-left: 12px;
    padding-right: 12px;
    min-height: 32px;
    background: white;
    border-bottom: 1px solid #dbe3eb;

    &.is-active {
      cursor: default;
      color: $color-text-900;
    }

    &:disabled {
      cursor: default;
      background: #f3f4f8;
      color: #8f9cac;
    }
  }

  &-dots {
    margin: 0 8px;
    min-height: 32px;
    display: flex;
    align-items: center;
  }

  &-text {
    margin-left: $space-2;
    margin-right: $space-2;
    vertical-align: middle;
    font-size: 14px;
    font-family: Inter;
    color: $color-text-900;
  }

  .select {
    height: 32px;
  }
}
