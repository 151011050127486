.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}
