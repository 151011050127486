.workspaceSelectionRow {
  font-size: 14px;
}

.workspaceSelectionRowActive {
  cursor: pointer;
}

.workspaceSelectionRowActive:hover {
  background-color: #4ab1ec;
  color: #f2f7fa !important;
}

.workspaceSelectionCell {
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 16px !important;
}

.showScrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.showScrollbar::-webkit-scrollbar-track {
  background-color: #aaa;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.showScrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.workspaceSelectionBox {
  margin-bottom: 24px;
  width: 100%;
  height: 33vh;
  overflow-y: scroll;
}

.workspaceHeadline {
  padding-left: 16px;
  font-weight: 600;
  color: #74808e;
  position: fixed;
  background-color: white;
  z-index: 2;
  width: calc(50em - 64px);
  text-transform: uppercase;
  pointer-events: none;
}

.workspaceDescription {
  margin-left: 25em;
  position: fixed;
  background-color: white;
  z-index: 3;
  width: calc(25em - 32px);
  cursor: default;
  padding-left: 16px;
  padding-right: 16px;
  pointer-events: none;
}

.copySection {
  margin-bottom: 36px;
}
