.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  background: white;

  th {
    padding: $space-2 $space-3;
    font-weight: 500;
    text-align: left;

    &.has-hover {
      &:hover {
        background-color: $color-ui-blue-200;
        cursor: pointer;
      }
    }

    .sort-icon {
      display: inline;
      vertical-align: middle;
      position: relative;
      &--asc {
        top: -2px;
      }
      &--desc {
        top: -2px;
      }
    }
  }

  th,
  td {
    font-size: $f7;
    vertical-align: middle;
  }

  td {
    padding: $space-3;
    border-top: 1px solid #e6e9ea;
    color: $color-text-600;
  }
}
