.h1 {
  height: $space-3;
}

.h2 {
  height: $space-4;
}

.h3 {
  height: $space-5;
}

.h4 {
  height: $space-6;
}

.h5 {
  height: $space-7;
}

.h6 {
  height: $space-8;
}

.h-0 {
  height: 0;
}

.h-1\/4 {
  height: 25%;
}

.h-1\/2 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.vh-1\/4 {
  height: 25vh;
}

.vh-1\/2 {
  height: 50vh;
}

.vh-3\/4 {
  height: 75vh;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.h-auto {
  height: auto;
}

.h-inherit {
  height: inherit;
}
