@import './assets/scss/tecton-pages/tecton-pages.scss';
@import './assets/scss/tecton-components/tecton-components.scss';

#root {
  width: 100%;
}

svg {
  fill: none;
}
