.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.own {
  overflow-wrap: normal;
}

.owa {
  overflow-wrap: anywhere;
}
