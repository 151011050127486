.ws-normal {
  white-space: normal;
}

.nowrap {
  white-space: nowrap;
}

.pre {
  white-space: pre;
}
