.fw2 {
  font-weight: 200;
}

.fw3 {
  font-weight: 300;
}

.fw4 {
  font-weight: 400;
}

.fw5 {
  font-weight: 500;
}

.fw6 {
  font-weight: 600;
}

.fw7 {
  font-weight: 700;
}

.fw8 {
  font-weight: 800;
}

.fw9 {
  font-weight: 900;
}
