.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #dbe3eb;
  color: $color-text-700;
  height: 38px;
  font-size: $f5;
  padding-left: 14px;
  padding-right: 32px;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5L3.5 3.5L6.5 0.5' stroke='%238F9CAC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-color: white;
  background-position: right 16px center;
  background-repeat: no-repeat;
  background-size: auto 4px;
  max-width: 100%;

  &:focus {
    border-color: $color-ui-blue;
  }

  &::-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 black;
  }

  &[disabled] {
    color: $color-text-300;
    border-color: #b6c5cf;
    background-color: #e9ecee;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5L3.5 3.5L6.5 0.5' stroke='%23cccccc' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    cursor: default;
  }
}
