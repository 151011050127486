.word-normal {
  word-break: normal;
}

.word-wrap {
  word-break: break-all;
}

.word-nowrap {
  word-break: keep-all;
}
