.status {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  padding: 1px 4px 2px;
  line-height: 10px;
  background: white;
  letter-spacing: 1px;
  border-color: #0fc47e;
  color: #098051;

  &__text {
    font-weight: 600;
    font-size: 8px;
    text-transform: uppercase;
  }

  &--disabled {
    border-color: #b6c5cf;
    color: #6c7682;
  }

  &--missing {
    border-color: #ec3b26;
    color: #ec3b26;
  }
}
