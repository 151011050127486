@import '../../assets/scss/variables';

.whats-new-anchor {
  position: relative;

  .whats-new-notification-dot {
    position: absolute;
    top: 20px; /* 24px (size of icon) - 8px (size of dot) / 2 */
    left: 20px;
  }

  .whats-new-notification-badge {
    display: inline-block;
    position: absolute;
    bottom: -6px;
    right: -6px;
    padding: 1px 1px 0.5px 1.5px;
    border-radius: 2px;
    line-height: 1;
    color: white;
    font-size: 7px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: $color-ui-blue;
  }

  .title-on-hover-over {
    position: absolute;
    bottom: calc(100% + 6px);
    width: 11rem;
    background: white;
    border-radius: 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 6px 9px 6px;
    z-index: 300;
    display: none;

    .whats-new-post-date {
      display: block;
      color: $color-ui-blue;
      font-weight: bold;
      font-size: 11px;
      text-transform: uppercase;
    }

    .whats-new-post-title {
      display: block;
      color: $color-ui-navy-400;
      font-size: 14px;
    }
  }
}

.whats-new-link:hover {
  .title-on-hover-over {
    display: block;
  }
}
