.avatar {
  display: block;
  border-radius: 50%;
  font-weight: 600;
  border: 1px solid rgba(2, 39, 56, 0.1);
  object-fit: cover;

  &--small {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }

  &--medium {
    width: 42px;
    height: 42px;
  }

  &--large {
    width: 64px;
    height: 64px;
    font-size: 30px;
  }

  &--initial {
    border-width: 2px;
    border-style: solid;
    text-align: center;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &--1 {
      border-color: #f2cb34;
      color: #f2cb34;
      background: #fff6d3;
    }

    &--2 {
      border-color: #18cce0;
      color: #18cce0;
      background: #d0f7fc;
    }

    &--3 {
      border-color: #7c4dff;
      color: #7c4dff;
      background: #e4dbff;
    }

    &--4 {
      border-color: #ec3b26;
      color: #ec3b26;
      background: #fddcd8;
    }

    &--5 {
      border-color: #39dab1;
      color: #39dab1;
      background: #c6f3e8;
    }
  }
}
