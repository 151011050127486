.lh-even {
  line-height: 1;
}

.lh-solid {
  line-height: 1.1;
}

.lh-title {
  line-height: 1.25;
}

.lh-copy {
  line-height: 1.45;
}

.lh-base {
  line-height: 1.6;
}
