.dropdown {
  position: absolute;
  background: #fff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: $space-2 $space-3;
    color: $color-text-900;
    font-size: $f6;

    &:hover {
      background: $color-ui-blue-200;
    }

    &__disabled {
      color: $color-text-700 !important;
      cursor: default;

      &:hover {
        background: #fff !important;
      }
    }
  }

  &__icon {
    margin-right: $space-2;
  }
}

.dropdown--profile {
  min-width: 150px;
  right: 0px;
  top: 50px;
  z-index: 10;
  &:before {
    position: absolute;
    top: -8px;
    right: 5px;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
}
