.crumbs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: $f5;

  li {
    padding-right: 8px;

    &:after {
      content: '/';
      padding-left: 6px;
      color: $color-text-300;
    }

    &:last-child {
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }

  .crumb-first {
    display: flex;
    align-items: center;

    &__icon {
      flex: none;
      margin-right: $space-2;
    }
  }
}
