.status-progression-box:not(:last-of-type) {
  border-bottom: 1px solid #c4c4c4;
}

.status-progression-in-progress {
  animation: spin-in-progress linear 2s infinite;
}

@keyframes spin-in-progress {
  0% {
    transform: rotate(0deg) scaleX(-1);
  }
  100% {
    transform: rotate(360deg) scaleX(-1);
  }
}
