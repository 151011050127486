.f3 {
  font-size: $f3;
}

.f4 {
  font-size: $f4;
}

.f5 {
  font-size: $f5;
}

.f6 {
  font-size: $f6;
}

.f7 {
  font-size: $f7;
}

.f8 {
  font-size: $f8;
}
